/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 7/4/20
 * Time: 5:02 PM
 */

export const SET_MODAL = 'SET_MODAL';
export const SET_PAYMENT_MODAL = 'SET_PAYMENT_MODAL';

