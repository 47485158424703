/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 7/4/20
 * Time: 5:02 PM
 */

export const SELECT_PAGE_ON_CLASS = 'SELECT_PAGE_ON_CLASS';
export const SELECT_PAGE_ON_MY_CLASS = 'SELECT_PAGE_ON_MY_CLASS';
export const SELECT_CLASS = 'SELECT_CLASS';
export const FETCH_LECTURES = 'FETCH_LECTURES';
export const FETCH_ALL_MY_CLASS = 'FETCH_ALL_MY_CLASS';
