/**
 * Created by WebStorm.
 * User: at7/4/20
 * Time: 5:02 PM
 */
import * as actionTypes from './actionType';

const initialState = {
    currentModal: null,
    isPaymentModal: false,
    isPaymentObj:null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MODAL:
            return {
                ...state,
                currentModal: action.value,
            };
        case actionTypes.SET_PAYMENT_MODAL:
            return {
                ...state,
                isPaymentModal: action.value,
                isPaymentObj: action.data ? action.data: null
            };
        default:
            return state;
    }
};

export default reducer;
