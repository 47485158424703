/**
 * Created by WebStorm.
 * User: at7/4/20
 * Time: 5:02 PM
 */
import * as actionTypes from './actionType';

const initialState = {
    selectClass: null,
    purchaseClasses: [

    ],
    classesByLecture: [],
    listOfLectures: [],
    pagination: {size:3, pageCount:1, page:0},
    paginationMyClass: {size:5, pageCount:1, page:0}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_CLASS:
            return {
                ...state,
                selectClass: action.value,

            };
        case actionTypes.FETCH_LECTURES:
            return {
                ...state,
                listOfLectures: action.value,

            };
        case actionTypes.SELECT_PAGE_ON_CLASS:
            return {
                ...state,
                pagination: action.value ? action.value : initialState.pagination,

            };
        case actionTypes.SELECT_PAGE_ON_MY_CLASS:
            return {
                ...state,
                paginationMyClass: action.value ? action.value : initialState.paginationMyClass,

            };
        case actionTypes.FETCH_ALL_MY_CLASS:
            return {
                ...state,
                purchaseClasses: action.value,
            };

        default:
            return state;
    }
};

export default reducer;
